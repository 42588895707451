<template>
  <div>
    <section class="section is-main-section" v-if="model">
      <div class="columns">
        <div class="column">
          <div style="font-size: 1.5em">
            <u>{{ title }}</u>
          </div>
          <div>
            ເລກທີ: <b>{{ model ? model.code : "" }}</b>
          </div>
          <div>
            ສາຂາຕົ້ນທາງ:
            <b
              >{{ model ? model.branch.code : "" }}
              {{ model ? model.branch.name : "" }}</b
            >
          </div>
          <div>
            ປ້າຍລົດ: <b>{{ model ? model.vehicle.license_no : "" }}</b>
          </div>
        </div>
        <div class="column has-text-right">
          <div>
            <span style="border: 5px solid #000000; font-size: 3em">
              <b>
                {{
                  model && model.branch_branchToshipment_script_to_branch_id
                    ? model.branch_branchToshipment_script_to_branch_id.name
                    : ""
                }}
              </b>
            </span>
          </div>
          <div style="margin-top: 0.5em">
            ວັນທີ: <b>{{ model.created_time | datetime }}</b>
          </div>
        </div>
      </div>
      <table
        v-if="model.shipment_script_detail"
        class="table is-fullwidth is-bordered"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>ລະຫັດ</th>
            <th>ຜູ້ສົ່ງ</th>
            <th>ຜູ້ຮັບ</th>
            <th>ຄ່າຝາກ</th>
            <th>ການຊໍາລະ</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in model.shipment_script_detail"
            :key="index"
          >
            <td>{{ index + 1 }}</td>
            <td>
              <div>{{ item.shipment.code }}</div>
              <ul
                v-if="item.shipment.parcel && item.shipment.parcel.length > 0"
              >
                <li v-for="(p, i) in item.shipment.parcel" :key="i">
                  - {{ p.parceltype.name }} x {{ p.qty }}
                </li>
              </ul>
            </td>
            <td>
              {{ item.shipment.sendername }} {{ item.shipment.sendertel }}
            </td>
            <td>
              {{ item.shipment.receivername }} {{ item.shipment.receivertel }}
            </td>
            <td class="has-text-right">
              {{ item.shipment.amount | formatnumber }}
            </td>
            <td>
              <div v-if="!item.shipment.is_receiver_pay">ຈ່າຍແລ້ວ</div>
              <div v-if="item.shipment.is_receiver_pay">
                ເກັບຄ່າສົ່ງປາຍທາງ
                {{ item.shipment.is_fee_in_cod ? " (ຫັກຈາກ COD)" : "" }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <div class="columns">
        <div class="column">
          <div class="has-text-centered">ລວມ</div>
          <div class="has-text-centered" style="font-size: 2em">
            {{
              _.sumBy(model.shipment_script_detail, (s) => {
                return s.shipment.amount;
              }) | formatnumber
            }}
          </div>
        </div>
        <div class="column">
          <div class="has-text-centered">ຈ່າຍແລ້ວ</div>
          <div class="has-text-centered" style="font-size: 2em">
            {{
              _.sumBy(model.shipment_script_detail, (s) => {
                return s.shipment.is_receiver_pay ? 0 : s.shipment.amount;
              }) | formatnumber
            }}
          </div>
        </div>
        <div class="column">
          <div class="has-text-centered">ເກັບຄ່າສົ່ງປາຍທາງ</div>
          <div class="has-text-centered" style="font-size: 2em">
            {{
              _.sumBy(model.shipment_script_detail, (s) => {
                return s.shipment.is_receiver_pay ? s.shipment.amount : 0;
              }) | formatnumber
            }}
          </div>
        </div>
      </div>
      <hr />
      <div class="columns">
        <div class="column has-text-centered">ລາຍເຊັນ ພະນັກງານສາຂາ</div>
        <div class="column has-text-centered">ລາຍເຊັນ ພະນັກງານຂັບລົດ</div>
      </div>
    </section>
  </div>
</template>

<script>
import Axios from "axios";
export default {
  name: "ShipmentScriptPrint",
  data() {
    return {
      code: null,
      title: "ໃບນໍາສົ່ງເຄື່ອງຝາກ",
      model: null,
    };
  },
  mounted() {
    this.code = this.$route.params["code"];
    this.get();
  },
  methods: {
    get() {
      Axios.get("shipmentscript/get/" + this.code)
        .then((r) => {
          this.model = r;
          this.$nextTick(() => {
            setTimeout(() => {
              window.print();
            }, 100);
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>

<style>
@page {
  size: A4;
  margin: 0;
}
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  /* ... the rest of the rules ... */
}
</style>
